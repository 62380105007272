<template>
    <div class="mt-16 md:mt-0">
        <div class="container mb-16">
            <div class="text-center">
                <h2 class="pb-[11px]">
                    {{ data.heading }}
                </h2>
                <WysiwygContent v-if="data.content" :content="data.content" />
            </div>
        </div>

        <div
            class="pb-14 transition-opacity duration-1000 lg:pb-28"
            :class="{ 'opacity-0': !carouselLoaded }"
        >
            <Swiper
                :centered-slides="true"
                :loop="true"
                :modules="[Pagination]"
                :pagination="true"
                slides-per-view="auto"
                @swiper="onSwiperInit"
            >
                <SwiperSlide
                    v-for="(gridItem, key) in data.items"
                    :key="key"
                    class="swiper-slide flex h-auto w-3/4 px-3.5 py-2 sm:w-4/5"
                >
                    <DifferentiatorGridItem
                        :item="gridItem"
                        class="cursor-grab"
                    />
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
</template>

<script setup lang="ts">
import DifferentiatorGridType from '@types/DifferentiatorGridType';
import DifferentiatorGridItem from '~/components/page-building/differentiator-grid/components/DifferentiatorGridItem.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';

defineProps<{
    data: DifferentiatorGridType;
}>();

const carouselLoaded = ref(false);

const onSwiperInit = () => {
    carouselLoaded.value = true;
};
</script>
