<template>
    <NuxtLink
        :to="
            item.link?.url
        "
        class="flex flex-1 text-inherit no-underline md:hover:cursor-pointer"
        @click="item.link?.anchor && scrollToId(item.link?.anchor)"
    >
        <div
            v-editable="item.vEditable"
            class="group flex flex-col justify-between rounded-xl bg-white p-6 text-left drop-shadow-[0_0_8px_rgba(20,20,20,0.1)] md:hover:bg-brew md:hover:text-white"
        >
            <div class="mb-4 flex justify-center md:block">
                <StoryblokImage
                    v-if="item.image"
                    class="aspect-auto"
                    :class="[
                        { 'md:group-hover:hidden': item.hoverImage },
                        imageSizeCss,
                    ]"
                    :src="item.image.src"
                    :alt="item.image.alt"
                />
                <StoryblokImage
                    v-if="item.hoverImage"
                    class="hidden aspect-auto md:group-hover:block"
                    :class="imageSizeCss"
                    :src="item.hoverImage.src"
                    :alt="item.hoverImage.alt"
                />
            </div>
            <div class="mb-4">
                <div
                    class="mb-2 text-[20px] font-bold text-grind md:group-hover:text-white"
                >
                    {{ item.title }}
                </div>
                <div
                    v-if="item.subtitle"
                    class="text-sm font-semibold uppercase tracking-wide text-drip md:group-hover:text-white"
                >
                    {{ item.subtitle }}
                </div>
            </div>
            <WysiwygContent
                v-if="item.content"
                :content="item.content"
                class="mb-4"
            />
            <div class="text-brew md:group-hover:text-white">
                {{ item.link.label }}
            </div>
        </div>
    </NuxtLink>
</template>

<script setup lang="ts">
import DifferentiatorGridItemType from '~/types/DifferentiatorGridItemType';
import { scrollToId } from '~/utils/helpers';

const props = defineProps<{
    item: DifferentiatorGridItemType;
}>();

const imageSizeCss = computed(() => {
    let css = 'h-[50px]';
    if (props.item.imageSize) {
        if (props.item.imageSize === 'large') {
            css = 'h-[240px]';
        } else if (props.item.imageSize === 'medium') {
            css = 'h-[120px]';
        }
    }
    return css;
});
</script>
