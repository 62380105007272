<template>
    <div>
        <div
            v-editable="item.vEditable"
            class="group flex w-[280px] flex-col justify-between rounded-xl border-[1px] border-solid border-neutral-200 bg-[#f0f0f0] p-6 text-left xl:w-[340px]"
        >
            <div class="mb-4">
                <StoryblokImage
                    v-if="item.image"
                    class="m-auto aspect-auto"
                    :class="[
                        { 'md:group-hover:hidden': item.hoverImage },
                        imageSizeCss,
                    ]"
                    :src="item.image.src"
                    :alt="item.image.alt"
                />
                <StoryblokImage
                    v-if="item.hoverImage"
                    class="m-auto hidden aspect-auto md:group-hover:block"
                    :class="imageSizeCss"
                    :src="item.hoverImage.src"
                    :alt="item.hoverImage.alt"
                />
            </div>
        </div>
        <div class="mb-4 text-center">
            <div
                class="mb-3 mt-6 text-[24px] font-bold text-grind md:group-hover:text-white"
            >
                {{ item.title }}
            </div>
            <div
                v-if="item.subtitle"
                class="mb-2 tracking-wide md:group-hover:text-white"
            >
                {{ item.subtitle }}
            </div>
        </div>
        <WysiwygContent
            v-if="item.content"
            :content="item.content"
            class="mb-4"
        />
        <div v-if="item.link" class="text-brew md:group-hover:text-white">
            {{ item.link.label }}
        </div>
    </div>
</template>

<script setup lang="ts">
import DifferentiatorGridItemType from '~/types/DifferentiatorGridItemType';

const props = defineProps<{
    item: DifferentiatorGridItemType;
}>();

const imageSizeCss = computed(() => {
    let css = 'h-[50px]';
    if (props.item.imageSize) {
        if (props.item.imageSize === 'large') {
            css = 'h-[240px]';
        } else if (props.item.imageSize === 'medium') {
            css = 'h-[120px]';
        }
    }
    return css;
});
</script>
