<template>
    <div class="py-28 md:px-0">
        <h2 class="mb-12 text-center md:text-left">
            {{ data.heading }}
        </h2>
        <div class="grid grid-cols-1 gap-[20px] lg:grid-cols-2">
            <DifferentiatorGridItemVariant02
                v-for="(item, key) in filteredItems"
                :key="key"
                :item="item"
            />
        </div>
        <div class="mt-16 flex justify-center">
            <button class="btn-primary" @click="showAll = !showAll">
                Show {{ showAll ? 'fewer' : 'more' }} products
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import DifferentiatorGridType from '@types/DifferentiatorGridType';
import DifferentiatorGridItem from '~/components/page-building/differentiator-grid/components/DifferentiatorGridItem.vue';
import DifferentiatorGridItemVariant02 from '~/components/page-building/differentiator-grid/components/variants/DifferentiatorGridItemVariant02.vue';

const props = defineProps<{
    data: DifferentiatorGridType;
}>();

const initialProductsToShow = ref(4);
const showAll = ref(false);

const filteredItems = computed(() => {
    return showAll.value
        ? props.data.items
        : props.data.items.slice(0, initialProductsToShow.value);
});
</script>
